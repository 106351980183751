<template>
    <service-policies
      :columns="columns"
      iconName="item-dnscf"
      containerId="div-dnscf"
      storeModuleName="dnscf"
      serviceName="dnscf"
      servicePath="cf"
      editionPath="/ds"
      storePath="dnssecure"      
      :createButtonPermissions='[{actions: ["POST"], path: "/subsystems/ds/content-policy-rules"}]'
      :visible_for_permissions="[{ actions: ['PUT'], path: '/subsystems/ds/content-policy-rules/.*' }]"
    ></service-policies>
  </template>
  
  <script>
    import ServicePolicies from '@/components/ServicePolicies/ServicePolicies';
    import Switch from '@/components/Tables/Formatters/NoFunctionalSwitch';
    import Tags from '@/components/Tables/Formatters/Tags';
    import SchedulerFormatter from '@/components/Tables/Formatters/Scheduler';
  
    export default {
      name: 'page-dns-cf',
      components: {
          'service-policies': ServicePolicies
      },
      data() {
        return {
          columns: [
            { prop: 'rule_name', label: this.t('name'), minWidth: 130 },
            { prop: 'status', label: this.t('status'), minWidth: 100, formatter: Switch, visible_for_permissions: [{actions: ["POST"], path: "/subsystems/ds/content-policy-rules"}] },
            { prop: 'source', label: this.t('source'), minWidth: 180, formatter: Tags, align: 'center' },
            { prop: 'categories', label: this.t('categories'), minWidth: 180, formatter: Tags, align: 'center' },            
            { prop: 'urls', label: this.t('domains'), minWidth: 120, formatter: Tags, align: 'center' },
            { prop: 'action', label: this.t('action'), minWidth: 100, formatter: Tags, align: 'center' }            
          ]
        }
      }
    }
  </script>
  
  <style>
  </style>